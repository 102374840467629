<template>
  <div class="preview-options" v-if="demos && demos.length > 0">
    <b-container>
      <b-row>
        <b-col cols="12" lg="6" v-for="demo in demos" :key="demo.id">
          <div class="preview-options__option">
            <section class="img-wrap ratio-80">
              <div class="img-content">
                <section
                  class="image-demo_section"
                  :style="{ backgroundImage: `url(${demo.template.path})` }"
                >
                  <div>
                    <b-img class="image-demo" fluid :src="demo.screen.path" />
                  </div>
                </section>
                <!-- <b-img
                  fluid
                  :style="`{ 'background': url(${demo.template.path}) }`"
                  :src="
                    require('@/assets/imgs/illustrations/demos/website.png')
                  "
                /> -->
              </div>
            </section>
            <figcaption v-text="demo.name" />
            <div class="preview-options__option__actions">
              <Button
                :href="demo.url"
                class="button button--outline  button--outline-primary"
                :text="$t('button.live_preview')"
              >
              </Button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "DemoOptions",
  data() {
    return {
      // baseColor: url()
    };
  },
  props: {
    demos: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.image-demo {
  position: absolute;
  z-index: -1;
  overflow: hidden !important;
  padding: 14px 0;
  max-height: 100%;
  right: 0;
  left: 0;
  margin: auto;
  &_section {
    position: initial;
    width: 100%;
    height: 100%;
  }
}
</style>
